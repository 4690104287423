body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --theme-bg-color: #2b506e;
  --theme-text-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rcw-widget-container {
  max-width: 370px;
}

.rcw-input {
  text-align: left;
}

.rcw-message > .rcw-client > .rcw-message-text {
  background-color: var(--theme-bg-color) !important;
  color: var(--theme-text-color);
}

.rcw-launcher {
  background-color: var(--theme-bg-color) !important;
  color: var(--theme-text-color) !important;
}

.rcw-header {
  background-color: var(--theme-bg-color) !important;
  color: var(--theme-text-color) !important;
}

.rcw-sender {
  border-radius: unset !important;
}

.rcw-message-system {
  color: #999999;
  font-size: 10px;
}
